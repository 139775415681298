import React, {useState, useEffect, useRef} from "react";
import {FileUpload} from "primereact/fileupload";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {AutoComplete} from "primereact/autocomplete";
import {Skeleton} from "primereact/skeleton";
import axios from "axios";
import {base, supply_contracts, projects_with_budget, contract_acceptances} from "../../../api";

export default function DataTableACTApplicationDialog({setDialogVisibility, toast, loadContent}) {
	const [uploadedFile, setUploadedFile] = useState(null);
	const [fileError, setFileError] = useState("");
	const [uploadDate, setUploadDate] = useState(null);
	const [contracts, setContracts] = useState([]);
	const [projects, setProjects] = useState([]);
	const [filteredContracts, setFilteredContracts] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [selectedContract, setSelectedContract] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);
	const [loadingContracts, setLoadingContracts] = useState(false);
	const [loadingProjects, setLoadingProjects] = useState(true);
	const [errorText, setErrortext] = useState();

	useEffect(() => {
		loadProjects();
	}, []);

	useEffect(() => {
		if (selectedProject) {
			setFilteredContracts([]);
			setErrortext([]);
			loadContracts(selectedProject.id);
		} else {
			setFilteredContracts([]);
		}
	}, [selectedProject]);

	const loadProjects = () => {
		setLoadingProjects(true);
		axios
			.get(base + projects_with_budget)
			.then(res => {
				setProjects(res.data);
				setFilteredProjects(res.data); // Set initially filtered projects
				setLoadingProjects(false);
			})
			.catch(error => {
				setLoadingProjects(false);
			});
	};

	const loadContracts = projectId => {
		setLoadingContracts(true);
		axios
			.get(base + supply_contracts)
			.then(res => {
				const filtered = res.data.filter(contract => contract.project === projectId);
				setContracts(filtered);
				setFilteredContracts(filtered);
				setLoadingContracts(false);
			})
			.catch(error => {
				setLoadingContracts(false);
			});
	};

	const handleFileSelect = event => {
		const file = event.files && event.files[0];

		if (file) {
			const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
			if (!allowedTypes.includes(file.type)) {
				setFileError("File type must be PDF, JPG, or PNG.");
				return;
			}
			if (file.size > 5 * 1024 * 1024) {
				setFileError("File size must be less than 5MB.");
				return;
			}

			setFileError("");
			setUploadedFile(file);
		} else {
		}
	};

	const uploadHandler = event => {
		handleFileSelect(event);
	};

	const handleSubmit = async () => {
		if (!uploadedFile || !uploadDate || !selectedContract || !selectedProject) {
			if (!uploadedFile);
			if (!uploadDate);
			if (!selectedProject);
			if (!selectedContract);
			return;
		}

		const formattedDate =
			uploadDate instanceof Date
				? uploadDate.toISOString().split("T")[0] // Format date as YYYY-MM-DD
				: uploadDate;

		const formData = new FormData();
		formData.append("acceptance_document", uploadedFile);
		formData.append("acceptance_date", formattedDate);
		formData.append("supply_contract", selectedContract.id);

		try {
			await axios.post(base + contract_acceptances, formData);
			loadContent();
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "The item is added successfully",
				life: 3000,
			});
			setDialogVisibility(false);
		} catch (error) {
			if (error.response && error.response.data) {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: error.response.data.supply_contract,
					life: 3000,
				});
				setErrortext(error.response.data.supply_contract);
			}
		}
	};

	const searchProjects = event => {
		if (!event.query.trim().length) {
			setFilteredProjects([...projects]);
		} else {
			const filtered = projects.filter(
				project =>
					project.name.toLowerCase().includes(event.query.toLowerCase()) ||
					project.id.toString().includes(event.query)
			);
			setFilteredProjects(filtered);
		}
	};

	const searchContracts = event => {
		if (!event.query.trim().length) {
			setFilteredContracts([...contracts]);
		} else {
			const filtered = contracts.filter(
				contract =>
					contract.formatted_contract_number.toLowerCase().includes(event.query.toLowerCase()) ||
					contract.id.toString().includes(event.query)
			);
			setFilteredContracts(filtered);
		}
	};

	return (
		<div className="col-12 p-0">
			<div className="w-100 fw-bolder mb-3">Upload File and Set Date</div>

			<div className="mb-4">
				{loadingProjects ? (
					<Skeleton className="w-100" height="3rem" />
				) : (
					<AutoComplete
						value={selectedProject}
						suggestions={filteredProjects}
						completeMethod={searchProjects}
						field="name"
						onChange={e => {
							console.log("Project Selected:", e.value); // Debug log
							setSelectedProject(e.value);
							setSelectedContract(null); // Clear selected contract when new project is selected
						}}
						placeholder="Select Project"
						className="w-100"
						panelStyle={{width: "300px"}}
						dropdown
						itemTemplate={item => (
							<div>
								<div className="text-dark">{item.full_name_am}</div>
								<small className="text-primary">
									{item.name || "No code available"} (ID: {item.id})
								</small>
							</div>
						)}
					/>
				)}
			</div>
			{selectedProject && (
				<div className="mb-4">
					{loadingContracts ? (
						<Skeleton className="w-100" height="3rem" />
					) : (
						<AutoComplete
							value={selectedContract}
							suggestions={filteredContracts}
							completeMethod={searchContracts}
							field="formatted_contract_number"
							onChange={e => {
								console.log("Contract Selected:", e.value); // Debug log
								setSelectedContract(e.value);
							}}
							placeholder="Select Contract"
							className="w-100"
							panelStyle={{width: "300px"}}
							dropdown
							itemTemplate={item => (
								<div>
									<div className="text-dark">{item.formatted_contract_number}</div>
									<small className="text-primary">
										{item.contract_number || "No code available"} (ID: {item.id})
									</small>
								</div>
							)}
							disabled={!selectedProject}
						/>
					)}
				</div>
			)}

			{selectedProject && selectedContract && (
				<>
					<div className="mb-4">
						<FileUpload
							name="file"
							customUpload
							maxFileSize={5242880} // 5MB limit
							accept="application/pdf,image/jpeg,image/png"
							auto={false}
							chooseLabel="Choose File"
							uploadHandler={uploadHandler} // Use the new uploadHandler function here
							onSelect={e => handleFileSelect(e)}
							className="w-100"
						/>
						{fileError && <p style={{color: "red"}}>{fileError}</p>}
					</div>

					{/* Date Picker */}
					<div className="mb-4">
						<label className="d-block mb-2">Select Date:</label>
						<Calendar
							value={uploadDate}
							onChange={e => setUploadDate(e.value)}
							dateFormat="yy-mm-dd"
							placeholder="YYYY-MM-DD"
							showIcon
						/>
					</div>
					{errorText && (
						<div className="text-danger py-2 text-center fw-bold text-uppercase">{errorText}</div>
					)}
					{/* Action Buttons */}
					<div className="d-flex justify-content-center border-top pt-5">
						<Button
							className="mx-2"
							rounded
							label="Close"
							severity="secondary"
							onClick={() => setDialogVisibility(false)}
						/>
						<Button
							rounded
							label="Submit"
							severity="primary"
							onClick={handleSubmit}
							className="mx-2"
						/>
					</div>
				</>
			)}
		</div>
	);
}
