import React, {useState, useEffect} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {FileUpload} from "primereact/fileupload";
import {
	base,
	partners as partnersUrl,
	expances_row_by_project,
	supply_contracts,
	locations_url,
	projects_with_budget,
} from "../../../api";

export default function EditContractAddForm({
	setEditDialogVisibility,
	loadContent,
	toast,
	contractData: initialData,
}) {
	const [contractData, setContractData] = useState({
		deliveryTime: null,
		warrantyPeriod: null,
		paymentPeriod: null,
		startEndDate: {start: null, end: null},
		contractDate: null,
		prepaymentPercentage: null,
		file: null,
	});
	const [oldPrepaymentPercentage, setOldPrepaymentPercentage] = useState(null);

	const [withoutVAT, setWithoutVAT] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [selectedBudget, setSelectedBudget] = useState(null);
	const [selectedPartner, setSelectedPartner] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);

	const [partners, setPartners] = useState([]);
	const [filteredPartners, setFilteredPartners] = useState([]);
	const [partnersLoading, setPartnersLoading] = useState(false);

	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [projectsLoading, setProjectsLoading] = useState(false);

	const [budgetOptions, setBudgetOptions] = useState([]);
	const [filteredBudgetOptions, setFilteredBudgetOptions] = useState([]);
	const [budgetLoading, setBudgetLoading] = useState(false);

	const [locations, setLocations] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);
	const [locationsLoading, setLocationsLoading] = useState(false);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setPartnersLoading(true);
		axios
			.get(base + partnersUrl)
			.then(response => {
				setPartners(response.data);
				setPartnersLoading(false);
			})
			.catch(error => {
				console.error("Error fetching partners:", error);
				setPartnersLoading(false);
			});

		setProjectsLoading(true);
		axios
			.get(base + projects_with_budget)
			.then(response => {
				setProjects(response.data);
				setProjectsLoading(false);
			})
			.catch(error => {
				console.error("Error fetching projects:", error);
				setProjectsLoading(false);
			});

		setLocationsLoading(true);
		axios
			.get(base + locations_url)
			.then(response => {
				setLocations(response.data);
				setLocationsLoading(false);
			})
			.catch(error => {
				console.error("Error fetching locations:", error);
				setLocationsLoading(false);
			});
	}, []);

	useEffect(() => {
		if (selectedProject?.id) {
			setBudgetLoading(true);
			axios
				.get(base + expances_row_by_project + selectedProject.id + "/")
				.then(res => {
					setBudgetOptions(res.data);
					setBudgetLoading(false);
				})
				.catch(error => {
					console.error("Error loading budget data:", error);
					setBudgetOptions([]);
					setBudgetLoading(false);
				});
		} else {
			setBudgetOptions([]);
			setSelectedBudget(null);
		}
	}, [selectedProject]);

	useEffect(() => {
		if (initialData) {
			setContractData({
				deliveryTime: initialData.delivery_time || null,
				warrantyPeriod: initialData.warranty_period || null,
				paymentPeriod: initialData.payment_period || null,
				startEndDate: {
					start: initialData.start_date ? new Date(initialData.start_date) : null,
					end: initialData.end_date ? new Date(initialData.end_date) : null,
				},
				contractDate: initialData.contract_date ? new Date(initialData.contract_date) : null,
				prepaymentPercentage: initialData.advance_payment || null,
				file: initialData.file || null,
			});
			setWithoutVAT(initialData.without_vat || false);

			setSelectedProject(initialData.project || null);
			setSelectedPartner(initialData.partner || null);
			setSelectedLocation(initialData.location || null);
			setSelectedBudget(initialData.budget || null);
		}
	}, [initialData]);

	const handleChange = (e, field) => {
		const value = e.value !== null && e.value !== undefined ? e.value : e.target.value;
		setContractData({...contractData, [field]: value});
	};

	const saveData = () => {
		const formData = new FormData();
		formData.append("project", selectedProject);
		formData.append("partner", selectedPartner);
		formData.append("budget", selectedBudget);
		formData.append("location", selectedLocation);
		formData.append("advance_payment", contractData.prepaymentPercentage || 0);
		formData.append(
			"contract_date",
			contractData.contractDate ? contractData.contractDate.toISOString().split("T")[0] : null
		);
		formData.append("delivery_time", parseInt(contractData.deliveryTime, 10) || 0);
		formData.append(
			"start_date",
			contractData.startEndDate.start
				? contractData.startEndDate.start.toISOString().split("T")[0]
				: null
		);
		formData.append(
			"end_date",
			contractData.startEndDate.end
				? contractData.startEndDate.end.toISOString().split("T")[0]
				: null
		);
		formData.append("warranty_period", parseInt(contractData.warrantyPeriod, 10) || 0);
		formData.append("payment_period", parseInt(contractData.paymentPeriod, 10) || 0);
		formData.append("without_vat", withoutVAT);
		formData.append("purchase_price", 0);
		if (contractData.file) {
			formData.append("approved_document", contractData.file);
		}

		axios
			.put(base + supply_contracts + `/${initialData.id}/`, formData, {
				headers: {"Content-Type": "multipart/form-data"},
			})
			.then(response => {
				loadContent();

				toast.current?.show({
					severity: "success",
					summary: "Success",
					detail: "Contract updated successfully!",
					life: 3000,
				});
				setEditDialogVisibility(false);
			})
			.catch(error => {
				toast.current?.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to update contract.",
					life: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const searchProject = event => {
		const query = event.query.toLowerCase();
		const filtered = projects.filter(project => {
			const nameMatch = project.full_name_am?.toLowerCase().includes(query);
			const codeMatch = project.name?.toLowerCase().includes(query);
			const idMatch = String(project.id).toLowerCase().includes(query);
			return nameMatch || codeMatch || idMatch;
		});
		setFilteredProjects(filtered);
	};

	const searchPartner = event => {
		const query = event.query.toLowerCase();
		const filtered = partners.filter(partner => {
			const nameMatch = partner.full_name_am?.toLowerCase().includes(query);
			const companyMatch = partner.company_am?.toLowerCase().includes(query);
			const passportMatch = partner.passport?.toLowerCase().includes(query);
			const phoneMatch = partner.phone?.toLowerCase().includes(query);
			const idMatch = String(partner.id).toLowerCase().includes(query);
			return nameMatch || companyMatch || passportMatch || phoneMatch || idMatch;
		});
		setFilteredPartners(filtered);
	};

	const searchBudget = event => {
		const query = event.query.toLowerCase();
		const filtered = budgetOptions.filter(budget => {
			const titleMatch = budget.category.title_am.toLowerCase().includes(query);
			const codeMatch = budget.category.code?.toLowerCase().includes(query);
			return titleMatch || codeMatch;
		});
		setFilteredBudgetOptions(filtered);
	};

	const searchLocation = event => {
		const query = event.query.toLowerCase();
		const filtered = locations.filter(location => location.title_am.toLowerCase().includes(query));
		setFilteredLocations(filtered);
	};

	const onFileSelect = event => {
		setContractData({...contractData, file: event.files[0]});
	};

	useEffect(() => {
		if (initialData && initialData.advance_payment) {
			setOldPrepaymentPercentage(initialData.advance_payment);
		}
	}, [initialData]);

	const toggleWithoutVAT = isChecked => {
		setWithoutVAT(isChecked);

		if (isChecked) {
			if (contractData.prepaymentPercentage !== 0) {
				setOldPrepaymentPercentage(contractData.prepaymentPercentage);
			}
			setContractData({...contractData, prepaymentPercentage: 0});
		} else {
			setContractData({...contractData, prepaymentPercentage: oldPrepaymentPercentage});
		}
	};
	console.log(selectedProject);

	return (
		<>
			<div className="row">
				<div className="field col-12 mb-3">
					<label htmlFor="project">Select Project</label>
					<AutoComplete
						id="project"
						value={selectedProject}
						suggestions={filteredProjects}
						completeMethod={searchProject}
						field="full_name_am"
						onChange={e => setSelectedProject(e.value)}
						placeholder="Search Project by Name or ID"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => (
							<div>
								<div className="text-dark">{item.full_name_am}</div>
								<small className="text-primary">
									{item.name || "No code available"} (ID: {item.id})
								</small>
							</div>
						)}
					/>
				</div>

				{selectedProject && (
					<div className="col-6">
						<label htmlFor="budget">Select Budget</label>
						<AutoComplete
							id="budget"
							value={selectedBudget}
							suggestions={filteredBudgetOptions}
							completeMethod={searchBudget}
							field="category.title_am"
							onChange={e => setSelectedBudget(e.value)}
							placeholder="Search Budget by Name or Code"
							dropdown
							className="w-100"
							panelStyle={{width: "300px"}}
							itemTemplate={item => (
								<div>
									<div className="text-dark">{item.category.title_am}</div>
									<small className="text-primary">
										{item.category.code || "No code available"}
									</small>
								</div>
							)}
							loading={budgetLoading}
						/>
					</div>
				)}

				<div className="col-6">
					<label htmlFor="partner">Select Partner</label>
					<AutoComplete
						id="partner"
						value={selectedPartner}
						suggestions={filteredPartners}
						completeMethod={searchPartner}
						field="full_name_am"
						onChange={e => setSelectedPartner(e.value)}
						placeholder="Search Partner by Name"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						loading={partnersLoading}
					/>
				</div>

				<div className="col-6">
					<label htmlFor="location">Select Location</label>
					<AutoComplete
						id="location"
						value={selectedLocation}
						suggestions={filteredLocations}
						completeMethod={searchLocation}
						field="title_am"
						onChange={e => setSelectedLocation(e.value)}
						placeholder="Search Location by Name or ID"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						loading={locationsLoading}
					/>
				</div>

				<div className="col-3">
					<label htmlFor="contractDate">Contract Date</label>
					<Calendar
						id="contractDate"
						value={contractData.contractDate}
						onChange={e => handleChange(e, "contractDate")}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="deliveryTime">Delivery Time (days)</label>
					<InputNumber
						id="deliveryTime"
						value={contractData.deliveryTime}
						onValueChange={e => handleChange(e, "deliveryTime")}
						mode="decimal"
						min={0}
						placeholder="Enter number of days"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="warrantyPeriod">Warranty Period</label>
					<InputNumber
						id="warrantyPeriod"
						value={contractData.warrantyPeriod}
						onValueChange={e => handleChange(e, "warrantyPeriod")}
						mode="decimal"
						min={0}
						placeholder="Enter warranty period"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="paymentPeriod">Payment Period</label>
					<InputNumber
						id="paymentPeriod"
						value={contractData.paymentPeriod}
						onValueChange={e => handleChange(e, "paymentPeriod")}
						mode="decimal"
						min={0}
						placeholder="Enter payment period"
						className="w-100"
					/>
				</div>

				<div className="col-3">
					<label htmlFor="startEndDateStart">Start Date</label>
					<Calendar
						id="startEndDateStart"
						value={contractData.startEndDate.start}
						onChange={e =>
							setContractData({
								...contractData,
								startEndDate: {...contractData.startEndDate, start: e.value},
							})
						}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="startEndDateEnd">End Date</label>
					<Calendar
						id="startEndDateEnd"
						value={contractData.startEndDate.end}
						onChange={e =>
							setContractData({
								...contractData,
								startEndDate: {...contractData.startEndDate, end: e.value},
							})
						}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="prepaymentPercentage">Prepayment Percentage</label>
					<InputNumber
						id="prepaymentPercentage"
						value={contractData.prepaymentPercentage}
						onValueChange={e => handleChange(e, "prepaymentPercentage")}
						mode="decimal"
						min={0}
						max={100}
						suffix="%"
						className="w-100"
					/>
				</div>
				<div className="col-3 d-flex align-items-center mt-4 pt-2">
					<Checkbox
						inputId="withoutVAT"
						checked={withoutVAT}
						onChange={e => toggleWithoutVAT(e.checked)}
					/>
					<label htmlFor="withoutVAT" className="ml-2">
						Առանց ԱԱՀ
					</label>
				</div>

				<div className="col-12">
					{initialData?.approved_document && (
						<div className="my-3">
							<label>Current File</label>
							<div>
								{initialData.approved_document.match(/\.(jpeg|jpg|gif|png|svg)$/i) ? (
									<img
										src={initialData.approved_document}
										alt="File Preview"
										style={{maxWidth: "100%", maxHeight: "100px"}}
										className="my-3"
									/>
								) : (
									<div className="mb-2">
										<i className="pi pi-file" style={{fontSize: "2rem"}}></i>
									</div>
								)}
								<a
									href={initialData.approved_document}
									target="_blank"
									rel="noopener noreferrer"
									download
									className="ms-3 text-primary"
								>
									Download Current File
								</a>
							</div>
						</div>
					)}
				</div>

				<div className="col-12 mt-3 mb-5">
					<label htmlFor="fileUpload">Upload File</label>
					<FileUpload
						name="file"
						auto
						customUpload
						uploadHandler={onFileSelect}
						chooseLabel="Select File"
						className="w-100"
					/>
				</div>

				<div className="col-12 text-center">
					<Button
						label="Cancel"
						rounded
						severity="secondary"
						icon="pi pi-times"
						className="mx-2"
						onClick={() => setEditDialogVisibility(false)}
						disabled={loading}
					/>
					<Button
						rounded
						label={loading ? "Saving..." : "Save"}
						icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"}
						className="mx-2"
						onClick={saveData}
						disabled={loading}
					/>
				</div>
			</div>
		</>
	);
}
