import React, {useState, useEffect} from "react";
import axios from "axios";
import {
	base,
	projects_url,
	unit,
	expance_type,
	contract_appendices,
	supply_contracts,
	demand_letters,
	projects_with_budget,
	contract_acceptances,
} from "../../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";

export default function ApplicationsDialog({setDialogVisibility}) {
	const [projectsOptions, setProjectsOptions] = useState([]);
	const [unitOptions, setUnitOptions] = useState([]);
	const [expanceTypes, setExpanceTypes] = useState([]);
	const [supplyContractsOptions, setSupplyContractsOptions] = useState([]);
	const [demandLetters, setDemandLetters] = useState([]);
	const [projectSuggestions, setProjectSuggestions] = useState([]);
	const [unitSuggestions, setUnitSuggestions] = useState([]);
	const [expanceSuggestions, setExpanceSuggestions] = useState([]);
	const [supplyContractSuggestions, setSupplyContractSuggestions] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const [selectedSupplyContract, setSelectedSupplyContract] = useState(null);
	const [rows, setRows] = useState([
		{name: "", description: "", unit: "", unitPrice: "", total: "", comments: ""},
	]);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all([
				axios.get(base + projects_with_budget),
				axios.get(base + unit),
				axios.get(base + expance_type),
				axios.get(base + supply_contracts),
				axios.get(base + demand_letters),
			])
			.then(
				axios.spread(
					(
						projectsResponse,
						unitResponse,
						expanceTypesResponse,
						supplyContractsResponse,
						demandLettersResponse
					) => {
						setProjectsOptions(projectsResponse.data);
						setUnitOptions(unitResponse.data);
						setExpanceTypes(expanceTypesResponse.data);
						setSupplyContractsOptions(supplyContractsResponse.data);
						setDemandLetters(demandLettersResponse.data);
					}
				)
			)
			.catch(error => console.error("Error loading data:", error));
	};
	console.log(demandLetters);

	const saveData = () => {
		if (!selectedProject || !selectedSupplyContract) {
			console.error("Project and Supply Contract are required.");
			return;
		}

		const validRows = rows.filter(row => {
			const isValidName = !!row.name?.id;
			const isValidUnit = !isNaN(parseFloat(row.unit));
			const isValidUnitPrice = !isNaN(parseFloat(row.unitPrice));
			return isValidName && isValidUnit && isValidUnitPrice;
		});

		// if (validRows.length === 0) {
		// 	console.error("No valid rows to send.");
		// 	return;
		// }

		const requestData = {
			project_id: selectedProject.id,
			supply_contract_id: selectedSupplyContract.id,
			data: validRows.map(row => ({
				name: row.name.id,
				description: row.description || null,
				unit: parseFloat(row.unit),
				unitPrice: parseFloat(row.unitPrice),
				total: parseFloat(row.total),
				comments: row.comments || null,
			})),
		};

		axios
			.post(base + contract_appendices, requestData, {
				headers: {"Content-Type": "application/json"},
			})
			.then(response => {
				console.log("Data saved successfully:", response.data);
				setDialogVisibility(false);
			})
			.catch(error => {
				console.error("Error saving data:", error);
			});
	};

	const handleRowChange = (value, field, index) => {
		const updatedRows = [...rows];
		updatedRows[index][field] = value;

		if (field === "unit" || field === "unitPrice") {
			const unit = parseFloat(updatedRows[index].unit) || 0;
			const unitPrice = parseFloat(updatedRows[index].unitPrice) || 0;
			updatedRows[index].total = (unit * unitPrice).toFixed(2);
		}

		setRows(updatedRows);
	};

	const addRow = () => {
		setRows([
			...rows,
			{name: "", description: "", unit: "", unitPrice: "", total: "", comments: ""},
		]);
	};

	const removeRow = index => {
		if (rows.length > 1) {
			setRows(rows.filter((_, i) => i !== index));
		}
	};

	const searchProjects = event => {
		const query = event.query.toLowerCase();
		setProjectSuggestions(
			projectsOptions.filter(
				p =>
					p.full_name_am.toLowerCase().includes(query) ||
					p.name.toLowerCase().includes(query) ||
					String(p.id).toLowerCase().includes(query)
			)
		);
	};

	const searchUnits = event => {
		setUnitSuggestions(
			unitOptions.filter(u => u.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const searchExpanceTypes = event => {
		setExpanceSuggestions(
			expanceTypes.filter(e => e.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const searchSupplyContracts = event => {
		if (!selectedProject) {
			setSupplyContractSuggestions([]);
			return;
		}

		const query = event.query.toLowerCase();

		const filteredContracts = supplyContractsOptions.filter(c => {
			const matchesQuery =
				String(c.contract_number).toLowerCase().includes(query) || // Match contract number
				c.formatted_contract_number?.toLowerCase().includes(query); // Match formatted number
			const matchesProject = c.project === selectedProject.id; // Match project ID

			return matchesQuery && matchesProject;
		});

		setSupplyContractSuggestions(filteredContracts);
	};

	const renderCellEditor = (field, rowIndex) => {
		if (field === "name") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={expanceSuggestions}
					completeMethod={searchExpanceTypes}
					field="title_am"
					onChange={e => handleRowChange(e.value, field, rowIndex)}
					dropdown
					className="border-0 w-100 rounded-0 px-0"
					panelStyle={{width: "250px"}}
				/>
			);
		} else if (field === "unit") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={unitSuggestions}
					completeMethod={searchUnits}
					field="title_am"
					onChange={e => handleRowChange(e.value, field, rowIndex)}
					dropdown
					className="border-0 w-100 rounded-0 px-0"
					panelStyle={{width: "150px"}}
				/>
			);
		} else {
			return (
				<InputText
					className="border-0 w-100 rounded-0 px-0"
					value={rows[rowIndex][field]}
					onChange={e => handleRowChange(e.target.value, field, rowIndex)}
				/>
			);
		}
	};

	return (
		<>
			<div className="mt-3">
				<div className="fw-bolder mb-3 text-muted">Ընտրել ծրագիրը</div>
				<AutoComplete
					value={selectedProject}
					suggestions={projectSuggestions}
					completeMethod={searchProjects}
					field="full_name_am"
					onChange={e => setSelectedProject(e.value)}
					placeholder="Select Project"
					dropdown
					className="w-100"
					panelStyle={{width: "300px"}}
					itemTemplate={item => (
						<div>
							<div className="text-dark">{item.full_name_am}</div>
							<small className="text-primary">
								{item.name || "No code available"} (ID: {item.id})
							</small>
						</div>
					)}
				/>
			</div>

			{selectedProject?.id && (
				<div className="col-12 mt-3">
					<div className="fw-bolder mb-3 text-muted">Ընտրել Մատակարարման Պայմանագիր</div>
					<AutoComplete
						value={selectedSupplyContract}
						suggestions={supplyContractSuggestions}
						completeMethod={searchSupplyContracts}
						field="formatted_contract_number"
						onChange={e => setSelectedSupplyContract(e.value)}
						placeholder="Search or Select Supply Contract"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => {
							const matchingProject = projectsOptions.find(project => project.id === item.project);
							return (
								<div>
									<div>{item.formatted_contract_number}</div>
									<small className="text-muted">
										{matchingProject?.full_name_am || "No project available"}
									</small>
								</div>
							);
						}}
					/>
				</div>
			)}

			{selectedProject?.id && selectedSupplyContract?.id && (
				<>
					<div className="mt-5">
						<div className="mb-3 fw-bolder">Ավելացնել հայտի կետեր</div>
						<DataTable value={rows} editMode="cell">
							<Column header="№" body={(_, {rowIndex}) => rowIndex + 1} style={{width: "50px"}} />
							<Column
								field="name"
								header="Անվանում"
								body={(rowData, {rowIndex}) => renderCellEditor("name", rowIndex)}
							/>
							<Column
								field="description"
								header="Բնութագիր"
								body={(rowData, {rowIndex}) => renderCellEditor("description", rowIndex)}
							/>
							<Column
								field="unit"
								header="Չափի միավոր"
								body={(rowData, {rowIndex}) => renderCellEditor("unit", rowIndex)}
							/>
							<Column
								field="unitPrice"
								header="Միավոր գին"
								body={(rowData, {rowIndex}) => renderCellEditor("unitPrice", rowIndex)}
							/>
							<Column
								field="total"
								header="Գումարը"
								body={rowData => <span>{rowData.total}</span>}
							/>
							<Column
								field="comments"
								header="Նշումներ"
								body={(rowData, {rowIndex}) => renderCellEditor("comments", rowIndex)}
							/>
							<Column
								header={null}
								body={(_, {rowIndex}) => (
									<Button
										text
										rounded
										icon="pi pi-minus"
										severity="danger"
										onClick={() => removeRow(rowIndex)}
									/>
								)}
							/>
						</DataTable>
					</div>

					<div className="row align-items-center mt-3 pt-3 border-top">
						<div className="col-7 text-end">
							<Button
								label="Cancel"
								icon="pi pi-times"
								className="mr-2"
								onClick={() => setDialogVisibility(false)}
							/>
							<Button label="Save" icon="pi pi-check" className="ml-2" onClick={saveData} />
						</div>
						<div className="col-5 text-end">
							<Button label="Add Row" icon="pi pi-plus" onClick={addRow} />
						</div>
					</div>
				</>
			)}
		</>
	);
}
