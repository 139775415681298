import React, {useState, useEffect} from "react";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {FileUpload} from "primereact/fileupload";
import {Button} from "primereact/button";
import axios from "axios";
import {base, supply_contracts} from "../../../api";

export default function EditACTApplicationDialog({rowData, toast, closeDialog, refreshData}) {
	const [editedDate, setEditedDate] = useState(null);
	const [uploadedFile, setUploadedFile] = useState(null);

	useEffect(() => {
		if (rowData?.acceptance_date) {
			setEditedDate(new Date(rowData.acceptance_date));
		}
	}, [rowData]);

	const handleFileUpload = event => {
		if (event.files.length > 0) {
			setUploadedFile(event.files[0]);
			toast?.current?.show({
				severity: "info",
				summary: "File Uploaded",
				detail: event.files[0].name,
				life: 3000,
			});
		}
	};

	const saveChanges = () => {
		if (!rowData?.id) {
			toast?.current?.show({
				severity: "error",
				summary: "Error",
				detail: "No valid row selected",
				life: 3000,
			});
			return;
		}

		const formData = new FormData();
		formData.append("id", rowData.id);
		if (uploadedFile) {
			formData.append("acceptance_document", uploadedFile);
		}
		formData.append(
			"acceptance_date",
			editedDate ? editedDate.toISOString().split("T")[0] : rowData.acceptance_date
		);

		axios
			.put(base + supply_contracts + `${rowData.id}/`, formData, {
				headers: {"Content-Type": "multipart/form-data"},
			})
			.then(() => {
				toast?.current?.show({
					severity: "success",
					summary: "Success",
					detail: "Changes saved successfully!",
					life: 3000,
				});
				closeDialog();
				refreshData();
			})
			.catch(error => {
				console.error("Error saving changes:", error);
				toast?.current?.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to save changes.",
					life: 3000,
				});
			});
	};

	return (
		<div>
			<div className="row mb-3">
				<div className="col-6">
					<label className="fw-bolder">Պայմանագրի համար</label>
					<InputText value={rowData.contract_name} disabled className="w-100" />
				</div>
				<div className="col-6">
					<label className="fw-bolder">Ակտի համար</label>
					<InputText
						value={rowData.formatted_contract_acceptance_number}
						disabled
						className="w-100"
					/>
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-6">
					<label className="fw-bolder">Ամսաթիվ</label>
					<Calendar
						value={editedDate}
						onChange={e => setEditedDate(e.value)}
						dateFormat="dd.mm.yy"
						className="w-100"
					/>
				</div>
				<div className="col-6">
					<label className="fw-bolder">Հին Ֆայլ</label>
					{rowData.acceptance_document ? (
						<a
							href={rowData.acceptance_document}
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-link"
							download
						>
							<Button
								icon="pi pi-download"
								label="Download"
								className="p-button-text p-button-plain"
							/>
						</a>
					) : (
						<span>No document available</span>
					)}
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-12">
					<label className="fw-bolder">Վերբեռնել նոր ֆայլ</label>
					<FileUpload
						name="acceptance_document"
						customUpload
						auto
						uploadHandler={handleFileUpload}
						chooseLabel="Upload File"
						className="w-100"
					/>
				</div>
			</div>
			<div className="mt-4 text-end">
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-secondary mr-2"
					onClick={closeDialog}
				/>
				<Button
					label="Save"
					icon="pi pi-check"
					className="p-button-primary"
					onClick={saveChanges}
				/>
			</div>
		</div>
	);
}
