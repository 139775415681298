// export const base = "https://e-dm.armavirdc.am:444";
export const base = "https://azk.webtest.am:444";
export const authURL = "/auth/";
export const authConfirmURL = "/auth_confirm/";
export const currency = "/core/currency/";
export const acc = "/core/acc/";
export const unit = "/core/unit/";
export const bank = "/core/bank/";
export const expance = "/core/expance/";
export const expance_type = "/core/expance_type/";
export const tax = "/core/taxes/";
export const donors = "/core/donors/";
export const educations = "/core/educations/";
export const payment_types = "/core/payment_types/";
export const organization_types = "/core/organization_types/";
export const service_type = "/core/service_types/";
export const position_and_service = "/core/position_and_service/";
export const workplace_url = "/core/workplace/";
export const coworker_url = "/project/coworker/";
export const organization_details = "/core/organization_details/";
export const languages_url = "/core/languages/";
export const ratings_url = "/rating/rating-questions/";
export const expances_row_create_update = "expance/expancerows/";
export const expances_row_by_project = "/expance/by-project/";
export const file_tags_url = "/core/file_tags/";
export const locations_url = "/core/locations/";
export const projects_url = "/project/projects/";
export const project_events_url = "/project/event/";
export const statuses_url = "/core/statuses/";
export const spheres_url = "/core/spheres/";
export const probation_period_url = "/core/probation_period/";
export const statuses = "/users/statuses/";
export const beneficiaries = "/users/beneficiaries/";
export const partners = "/users/partners/";
export const partnersLegal = "/users/legal/";
export const partnersIndividual = "/users/individual/";
export const partnersPhysical = "/users/physical/";
export const usersURL = "/users/user/";
export const myProject = "/project/my_projects/";
export const changePasswordURL = "/users/change-password/";
export const resetPasswordURL = "/users/reset-password/";
export const userProfile = "/users/own_profile/";
export const groupsURL = "/users/groups/";
export const permissionsURL = "/users/permissions/";
export const updateGroupsAndPermissions = base + "/users/update_groups_and_permissions/";
export const docs_application_contracts = "/docs/contracts/application_contract/";
export const docs_application_contracts_single_upload = "/docs/contracts/application_contract_document/";
export const docs_application_contracts_single_download = "/docs/download-job-file/";
export const docs_application_download_zip = "/docs/download-job/";
export const docs_list = "/docs/list/";
export const docs_remove = "/docs/document/";
export const docs_upload = "/docs/upload/";
export const budget_by_project = "/budget/by-project/";
export const budget_by_project_create = "/budget/create/";
export const budget_by_project_row_get = "/budget/budget-rows/by-budget/";
export const budget_budget_row_create = "/budget/budget-rows/create/";

export const currencyRateApi = "https://cb.am/latest.json.php?currency=";
export const currencyRateApi_test = "https://v6.exchangerate-api.com/v6/9de8bfc4546edc5a0a36f7d7/latest/";



// Փաստաթղթավորում

// Գնումնեերի գործընթաց
export const projects_with_budget = "/project/projects_with_budget/"
export const demand_letters = "/expance/demand-letters/"
export const supply_contracts = "/expance/supply-contracts/"
export const contract_appendices = "/expance/contract-appendices/"
export const contract_acceptances = "/expance/contract-acceptances/"


export const ticket_chat = {
	ticket: base + "/ticket_chat/ticket/",
	message: base + "/ticket_chat/message/",
	ticket_messages: (_, ticketId) => base + `/ticket_chat/message/?ticket_id=${ticketId}`,
};

export const file_manager = {
	change_file_folder: "/file_manager/change-folder/file/",
	change_folder_parent: "/file_manager/change-folder/folder/",
	//
	get_public_folders: "/file_manager/public",
	get_my_folders: "/file_manager/my",
	//
	create_folder: "/file_manager/create_folder/",
	delete_folder: "/file_manager/delete_folder/",
	//
	upload_public_file: "/file_manager/upload_public_file/",
	delete_public_file: (_, fileId) => `/file_manager/delete_public_file/${fileId}/`,
	file_visibility_update: (_, fileId) => `/file_manager/file_visibility_update/${fileId}/`,
	//
	rename_public_file: (_, fileId) => `/file_manager/rename_public_file/${fileId}/`,
	rename_file: (_, fileId) => `/file_manager/rename_file/${fileId}/`,
	//
	download_public_file: (_, fileId) => `/file_manager/download_public_file/${fileId}/`,
	download_user_file: (_, fileId) => `/file_manager/download_user_file/${fileId}/`,
	upload_file: (_, folderId) => `/file_manager/upload_file/${folderId}/`,
	delete_file: (_, fileId) => `/file_manager/delete_file/${fileId}/`,
	delete_public_file: (_, fileId) => `/file_manager/delete_public_file/${fileId}/`,
};
