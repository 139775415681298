import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Skeleton} from "primereact/skeleton";
import axios from "axios";
import {base, contract_acceptances, supply_contracts} from "../../../api";
import DataTableACTApplicationDialog from "./DataTableACTApplicationDialog";
import EditACTApplicationDialog from "./EditACTApplicationDialog";

export default function DataTableACTApplication(props) {
	const [products, setProducts] = useState([]);
	const [supplyContracts, setSupplyContracts] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [editDialogVisibility, setEditDialogVisibility] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const toast = useRef(null);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all([axios.get(base + supply_contracts), axios.get(base + contract_acceptances)])
			.then(
				axios.spread((contractsResponse, acceptancesResponse) => {
					const contracts = contractsResponse.data;
					const acceptances = acceptancesResponse.data.map(acceptance => {
						const matchingContract = contracts.find(
							contract => contract.id === acceptance.supply_contract
						);
						return {
							...acceptance,
							contract_name: matchingContract
								? matchingContract.formatted_contract_number
								: "Unknown Contract",
						};
					});

					const sortedProducts = acceptances.sort((a, b) => b.id - a.id);
					setProducts(sortedProducts);
					setSupplyContracts(contracts);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
				if (toast.current) {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to load content.",
						life: 3000,
					});
				}
			});
	};

	const fileBodyTemplate = rowData => {
		const shortenFileName = (name, maxLength = 10) => {
			if (!name) {
				return "No Document";
			}
			if (name.length > maxLength) {
				return `...${name.slice(-maxLength)}`;
			}
			return name;
		};

		return (
			<a
				target="blank"
				href={rowData.acceptance_document || "#"}
				download
				title={rowData.acceptance_document || "No Document"}
			>
				{shortenFileName(rowData.acceptance_document)}
			</a>
		);
	};

	const dateBody = rowData => {
		const formatDate = dateString => {
			const date = new Date(dateString);
			const day = String(date.getDate()).padStart(2, "0");
			const month = String(date.getMonth() + 1).padStart(2, "0");
			const year = date.getFullYear();
			return `${day}.${month}.${year}`;
		};

		return formatDate(rowData.acceptance_date);
	};

	const onRowClick = rowData => {
		setSelectedRowData(rowData);
		setEditDialogVisibility(true);
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<div className="row pb-5">
				<div className="col text-end">
					<Button label="Նոր Ակտի հավելված" rounded onClick={() => setDialogVisibility(true)} />
				</div>
			</div>
			{products.length > 0 ? (
				<DataTable
					value={products}
					dataKey="id"
					tableStyle={{minWidth: "60rem"}}
					rowHover
					onRowClick={e => onRowClick(e.data)}
				>
					<Column field="contract_name" header="Պայմանագրի համար"></Column>
					<Column field="formatted_contract_acceptance_number" header="Ակտի համար"></Column>
					<Column header="Ամսաթիվ" body={dateBody}></Column>
					<Column field="uploaded_file" header="Ֆայլ" body={fileBodyTemplate}></Column>
				</DataTable>
			) : (
				<div>
					<span className="small">Loading Please wait</span>
					<Skeleton className="w-100 mt-2" height="3rem"></Skeleton>
				</div>
			)}
			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Նոր Ակտի հավելված</div>}
			>
				<DataTableACTApplicationDialog
					toast={toast}
					setDialogVisibility={setDialogVisibility}
					loadContent={loadContent}
				/>
			</Dialog>

			<Dialog
				visible={editDialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setEditDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Փոփոխել Ակտը</div>}
			>
				<EditACTApplicationDialog rowData={selectedRowData} />
			</Dialog>
		</div>
	);
}
