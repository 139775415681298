import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {ProductService} from "../../projects/Components/Budget/components/json";
import {Dialog} from "primereact/dialog";
import {base, expances_row_by_project, contract_appendices} from "../../../api";
import axios from "axios";
import {Button} from "primereact/button";
import NewContractAddForm from "./NewContractAddForm";
import DemandLetterDialog from "./DemandLetterDialog";
import ApplicationsDialog from "./ApplicationsDialog";

export default function DataTableDataApplications(props) {
	const [products, setProducts] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const toast = useRef(null);

	// useEffect(() => {
	// 	ProductService.getProductsWithOrdersSmall().then(data => setProducts(data));
	// }, []);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all([axios.get(base + contract_appendices)])
			.then(
				axios.spread((productResponse, partnersResponse, projectsResponse) => {
					const sortedProducts = productResponse.data.sort((a, b) => b.id - a.id);
					setProducts(sortedProducts);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
				if (toast.current) {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to load content.",
						life: 3000,
					});
				}
			});
	};

	const formatCurrency = value => {
		return value.toLocaleString("en-US", {style: "currency", currency: "USD"});
	};

	const amountBodyTemplate = rowData => {
		return formatCurrency(rowData.amount);
	};

	const searchBodyTemplate = () => {
		return <Button icon="pi pi-search" />;
	};

	const rowExpansionTemplate = data => {
		return (
			<div className="p-3">
				<h5>Orders for {data.name}</h5>
				<DataTable value={data.orders}>
					<Column field="id" header="Id" sortable></Column>
					<Column field="customer" header="Customer" sortable></Column>
					<Column field="date" header="Date" sortable></Column>
					<Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column>
					<Column headerStyle={{width: "4rem"}} body={searchBodyTemplate}></Column>
				</DataTable>
			</div>
		);
	};

	const contractIDBody = rowData => {
		return <div className="fst-italic">#{rowData?.id.substring(2, 4)}</div>;
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<div className="row pb-5">
				<div className="col text-end">
					<Button label={"Նոր հավելված"} rounded onClick={() => setDialogVisibility(true)} />
				</div>
			</div>
			<div style={{maxHeight: "500px", overflowY: "auto"}}>
				<DataTable
					value={products}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="id"
					tableStyle={{minWidth: "60rem"}}
				>
					<Column field="id" header="Պայմանագրի համար" body={contractIDBody} className="col-1" />
					<Column field="name" header="Company" className="col-3" />
				</DataTable>
			</div>
			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Նոր Հավելված</div>}
			>
				<ApplicationsDialog setDialogVisibility={setDialogVisibility} />
				{/* <NewContractAddForm setDialogVisibility={setDialogVisibility} /> */}
			</Dialog>
		</div>
	);
}
