import React, {useState, useEffect} from "react";
import axios from "axios";
import {
	base,
	unit,
	expance_type,
	demand_letters,
	expances_row_by_project,
	projects_with_budget,
} from "../../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";

export default function DemandLetterDialog({setDialogVisibility, loadContent, toast}) {
	const [unitOptions, setUnitOptions] = useState([]);
	const [budgetOptions, setBudgetOptions] = useState([]);
	const [projectsOptions, setProjectsOptions] = useState([]);
	const [expanceTypes, setExpanceTypes] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const [selectedBudget, setSelectedBudget] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null); // New state for single date
	const headers = {"Content-Type": "application/json"};

	const [rows, setRows] = useState([{product: "", description: "", unit: "", count: ""}]);
	const [unitSuggestions, setUnitSuggestions] = useState([]);
	const [expanceSuggestions, setExpanceSuggestions] = useState([]);
	const [projectSuggestions, setProjectSuggestions] = useState([]);
	const [budgetSuggestions, setBudgetSuggestions] = useState([]);

	// Load content on mount
	useEffect(() => {
		loadContentData();
	}, []);

	// Fetch unit, expense types, and projects
	const loadContentData = () => {
		axios
			.all([
				axios.get(base + unit),
				axios.get(base + expance_type),
				axios.get(base + projects_with_budget),
			])
			.then(
				axios.spread((unitResponse, expanceTypesResponse, projectsResponse) => {
					setUnitOptions(unitResponse.data);
					setProjectsOptions(projectsResponse.data);
					setExpanceTypes(expanceTypesResponse.data);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
				if (toast.current) {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to load content.",
						life: 3000,
					});
				}
			});
	};

	// Fetch budget details by selected project
	useEffect(() => {
		if (selectedProject?.id) {
			axios
				.get(base + expances_row_by_project + selectedProject.id + "/")
				.then(res => {
					const data = res.data;
					setBudgetOptions(Array.isArray(data) ? data : [data]); // Ensure it's an array
				})
				.catch(error => {
					console.error("Error loading budget data:", error);
					setBudgetOptions([]);
				});
		}
	}, [selectedProject?.id]);

	const handleRowChange = (value, field, index) => {
		const updatedRows = [...rows];
		updatedRows[index][field] = value;
		setRows(updatedRows);
	};

	const addRow = () => {
		setRows([...rows, {product: "", description: "", unit: "", count: ""}]);
	};

	const removeRow = index => {
		setRows(rows.filter((_, i) => i !== index));
	};

	const searchUnits = event => {
		setUnitSuggestions(
			unitOptions.filter(u => u.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const searchExpanceTypes = event => {
		setExpanceSuggestions(
			expanceTypes.filter(e => e.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const searchProjects = event => {
		const query = event.query.toLowerCase();
		setProjectSuggestions(
			projectsOptions.filter(
				p =>
					p.full_name_am.toLowerCase().includes(query) ||
					p.name.toLowerCase().includes(query) ||
					String(p.id).toLowerCase().includes(query)
			)
		);
	};

	const searchBudgets = event => {
		const query = event.query.toLowerCase();
		setBudgetSuggestions(
			Array.isArray(budgetOptions)
				? budgetOptions.filter(
						budget =>
							budget.category.title_am.toLowerCase().includes(query) ||
							budget.category.code?.toLowerCase().includes(query)
				  )
				: []
		);
	};

	const renderCellEditor = (field, rowIndex) => {
		if (field === "product") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={expanceSuggestions}
					completeMethod={searchExpanceTypes}
					field="title_am"
					onChange={e => handleRowChange(e.value, field, rowIndex)}
					dropdown
					className="border-0 w-100 rounded-0 px-0"
				/>
			);
		} else if (field === "unit") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={unitSuggestions}
					completeMethod={searchUnits}
					field="title_am"
					onChange={e => handleRowChange(e.value, field, rowIndex)}
					dropdown
					className="border-0 w-100 rounded-0 px-0"
				/>
			);
		} else {
			return (
				<InputText
					className="border-0 w-100 rounded-0 px-0"
					value={rows[rowIndex][field]}
					onChange={e => handleRowChange(e.target.value, field, rowIndex)}
				/>
			);
		}
	};

	const saveData = () => {
		if (!selectedProject || !selectedBudget || !selectedDate) {
			if (toast.current) {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Project, Budget, and Date are required",
					life: 3000,
				});
			}
			return;
		}

		const validProducts = rows.filter(row => row.product?.id && !isNaN(Number(row.count)));

		if (validProducts.length === 0) {
			if (toast.current) {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "No valid products to send",
					life: 3000,
				});
			}
			return;
		}

		const requestData = {
			project_id: selectedProject.id,
			budget_id: selectedBudget.id,
			date: selectedDate.toISOString().split("T")[0], // Include the selected date
			demand_letter_products: validProducts.map(row => ({
				product: row.product.id,
				count: parseInt(row.count, 10),
				unit: row.unit.id || null,
				description: row.description || null,
			})),
		};

		axios
			.post(base + demand_letters, requestData, {headers})
			.then(response => {
				if (toast.current) {
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Data saved successfully!",
						life: 3000,
					});
				}
				setDialogVisibility(false);
				loadContent();
			})
			.catch(error => {
				if (toast.current) {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to save data.",
						life: 3000,
					});
				}
			});
	};

	return (
		<>
			<div className="mt-3 row">
				<div className={"col-12"}>
					<div className="fw-bolder mb-3 text-muted">Ընտրել ծրագիրը</div>
					<AutoComplete
						value={selectedProject}
						suggestions={projectSuggestions}
						completeMethod={searchProjects}
						field="full_name_am"
						onChange={e => setSelectedProject(e.value)}
						placeholder="Select Project"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => (
							<div>
								<div className="text-dark">{item.full_name_am}</div>
								<small className="text-primary">
									{item.name || "No code available"} (ID: {item.id})
								</small>
							</div>
						)}
					/>
				</div>
				{selectedProject?.id && (
					<div className={"col-12"}>
						<div className="fw-bolder mb-3 text-muted">Ընտրել բյուջեն</div>
						<AutoComplete
							value={selectedBudget}
							suggestions={budgetSuggestions}
							completeMethod={searchBudgets}
							field="category.title_am"
							onChange={e => setSelectedBudget(e.value)}
							placeholder="Search or Select Budget"
							dropdown
							className="w-100"
							panelStyle={{width: "300px"}}
							itemTemplate={item => (
								<div>
									<div>{item.category.title_am}</div>
									<small className="text-muted">{item.category.code || "No code available"}</small>
								</div>
							)}
						/>
					</div>
				)}
			</div>
			{selectedProject && selectedBudget && (
				<div className="mt-3">
					<div className="mb-3 fw-bolder">Ընտրել ամսաթիվ</div>
					<Calendar
						value={selectedDate}
						onChange={e => setSelectedDate(e.value)}
						dateFormat="dd.mm.yy"
						className="mb-3 col-3"
						showIcon
					/>
					<div className="mb-3 fw-bolder">Ավելացնել պահանջագիր կետեր</div>
					<DataTable value={rows} editMode="cell">
						<Column header="№" body={(_, {rowIndex}) => rowIndex + 1} style={{width: "50px"}} />
						<Column
							field="product"
							header="Ապրանքի անվանում"
							body={(rowData, {rowIndex}) => renderCellEditor("product", rowIndex)}
						/>
						<Column
							field="unit"
							header="Չափի միավոր"
							body={(rowData, {rowIndex}) => renderCellEditor("unit", rowIndex)}
						/>
						<Column
							field="count"
							header="Քանակ"
							body={(rowData, {rowIndex}) => renderCellEditor("count", rowIndex)}
						/>
						<Column
							field="description"
							header="Ապրանքի բնութագիր"
							body={(rowData, {rowIndex}) => renderCellEditor("description", rowIndex)}
						/>
						<Column
							body={(_, {rowIndex}) => (
								<Button
									icon="pi pi-trash"
									className="fw-bolder m-1"
									rounded
									severity="danger"
									onClick={() => removeRow(rowIndex)}
								/>
							)}
						/>
					</DataTable>
					<div className="row my-4">
						<div className="col-7 text-end">
							<Button
								rounded
								label="Cancel"
								icon="pi pi-times"
								severity="secondary"
								onClick={() => {
									setRows([{product: "", description: "", unit: "", count: ""}]);
									setDialogVisibility(false);
								}}
							/>
							<Button
								rounded
								label="Save"
								icon="pi pi-check"
								severity="primary"
								className="mx-2"
								onClick={saveData}
							/>
						</div>
						<div className="col-5 text-end">
							<Button
								rounded
								label="Add Row"
								icon="pi pi-plus"
								severity="success"
								onClick={addRow}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
